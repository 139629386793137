@import '../../../../Frontend/scss/layout-variables';

.error-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7.5rem 0;
  min-height: 25rem;
  
  &.permission-denied {
    padding: 0;
  }

  & img {
    width: 17.5rem;
  }

  & h2,
  & p {
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
    text-align: center;
  }

  @media (min-width: $sm) {
    & img {
      width: auto;
    }
  }

  @media (min-width: $md) {
    padding: 13.75rem 0 18.75rem 0;
  }

  &__search {
    position: relative;
    margin-top: 1.875rem;

    .search-app {
      background-color: transparent;
      margin-top: 1.25rem;
      width: 100%;

      @media(min-width: $sm) {
        width: 38rem;
      }

      .search-wrapper__recommendations-container {
        padding: 0;
      }
    }
  }
  
  .active-customer-bar {
    margin-bottom: 2.5rem;
    width: 100%;
  }
}